import React from 'react';
import Image from 'next/image';
import { NextSeo } from 'next-seo';
import { useKeenSlider } from 'keen-slider/react';
import classNames from 'classnames';
import { baseImageUrl } from '../constants';
import Logo from '../components/Logo';
import 'keen-slider/keen-slider.min.css';
import styles from '../styles/Home.module.css';

export default function Main() {
  const timer = React.useRef();
  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    loop: true,
    defaultAnimation: { duration: 3000 },
  });

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (slider.current) {
        slider.current.next();
      }
    }, 10000);

    return () => {
      clearInterval(timer.current);
    };
  }, [slider]);

  return (
    <>
      <NextSeo title="Home" />
      <div>
        <div className="relative">
          <div
            className={classNames(
              'w-full h-full absolute 0 z-10 flex-center text-center text-white bg-gray-400 bg-opacity-40',
              styles.harana
            )}
          >
            <div>
              <Logo
                className={classNames(
                  'max-w-xs md:max-w-sm m-auto mb-2 md:mb-16 p-2 overflow-visible transform-gpu scale-75 md:scale-100',
                  styles.logo
                )}
              />
              <h1 className="text-7xl md:text-9xl mb-4 md:mt-4">HARANA</h1>
              <div className="subtitle text-3xl md:text-4xl">
                {`LET'S CELEBRATE TOGETHER`}
              </div>
            </div>
          </div>

          <div
            ref={sliderRef}
            className="keen-slider blur-sm"
            style={{ height: 'calc(100vh - 60px)' }}
          >
            <div className="keen-slider__slide flex-center">
              <Image
                src={`${baseImageUrl}/v1627514097/harana/ceremony_hzx3ek.jpg`}
                alt="wedding photo"
                layout="fill"
                objectFit="cover"
                priority
              />
            </div>
            <div className="keen-slider__slide flex-center">
              <Image
                src={`${baseImageUrl}/v1627514096/harana/truck_tnkayh.jpg`}
                alt="wedding photo"
                layout="fill"
                objectFit="cover"
              />
            </div>
            <div className="keen-slider__slide flex-center">
              <Image
                src={`${baseImageUrl}/v1627514097/harana/table_fhczgc.jpg`}
                alt="wedding photo"
                layout="fill"
                objectFit="cover"
              />
            </div>
            <div className="keen-slider__slide flex-center">
              <Image
                src={`${baseImageUrl}/v1627514096/harana/dessert_jva3sc.jpg`}
                alt="wedding photo"
                layout="fill"
                objectFit="cover"
              />
            </div>
            <div className="keen-slider__slide flex-center">
              <Image
                src={`${baseImageUrl}/v1627514104/harana/groom_rea3vr.jpg`}
                alt="wedding photo"
                layout="fill"
                objectFit="cover"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
